import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { User, UsersService } from '../services/users/users.service';
import { Dictionary } from 'src/app/shared/models/global';

@Injectable({
  providedIn: 'root'
})
export class UsersResolver  {
  constructor(
    private userService: UsersService,
    private appState: AppStateService,
  ) {

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User[] | boolean> {

    const facilityId = route.params.facilityId || route.children[0].params.facilityId
    if (facilityId) {
      const usersCollection = this.appState.get<Dictionary<User[]>>('usersCollection') || {};
      const users = usersCollection[facilityId];

      if (Array.isArray(users) && users.length) {
        return of(users);
      } else {
        return this.userService.getUsers({
          facilityId,
          userType: 'USER'
        });
      }
    }
    return of([]);

  }
}
